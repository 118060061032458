import React from 'react'
import Layout from '../components/layout'

import Menu from '../components/menu'

import NameLogo from '../images/writing.png'

const NotFoundPage = () => (
  <Layout>
    <div className="landing">
      <img src={NameLogo} alt="Beakal Asefa" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
    <div>
      <Menu />
    </div>
  </Layout>
)

export default NotFoundPage
